import React from "react";
import Lead from "../components/sections/lead";
import damixaMobileLead from "../images/damixa-lead-bg--mobile@1x.jpg";
import damixaLead from "../images/damixa-lead-bg@1x.jpg";
import Featured from "../components/sections/featured";
import Text from "../components/sections/text";
import Feedback from "../components/sections/feedback";
import Footer from "../components/sections/footer";
import DamixaCommercial from "../components/sections/damixaCommercial";
import leadVideo from "../video/damixa.mp4";
import damixaLogo from "../images/damixa-logo--white.svg";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const Damixa = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <Helmet>
        <html lang='ru' />
        <title>{t('lead.seo.damixa')}</title>
      </Helmet>
      <Lead
        leadImage={damixaLogo}
        title={t('lead.damixa.lead-text')}
        leadVideo={leadVideo}
      />
      <Featured
        imageMobile={damixaMobileLead}
        image={damixaLead}
        featuredTitle={t('lead.damixa.second-screen-big')}
        featuredText={t('lead.damixa.second-screen-text')}
        titleClass={`big-text--black`}
        textClass={`featured__text--black`}
      />
      <Text
        titleText={t('lead.damixa.pre-title')}
        bigText={t('lead.damixa.third-screen-big')}
      />
      <DamixaCommercial />
      <Feedback />
      <Footer />
    </>
  );
};

export default Damixa;
